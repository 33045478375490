<template>  
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="contact"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    
    <main class="main-wrap">
      <div class="content-wrap">
        <ContactForm />
      </div>
    </main>
  </body>
</template>

<script>
import modal from "@/components/ContactModal.vue";
import ContactForm from "@/components/ContactForm.vue";

export default{  
 name: "Contact",
  components: {
    modal, // eslint-disable-line vue/no-unused-components
    ContactForm
  },
  metaInfo() {
    return {
    }
  }, 
  data() {
    return {
      isModalVisible: false
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  },  
};

</script>

<style lang="scss">
</style>