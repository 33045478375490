<template>
  <div>
  <!-- Footer -->
    <footer
      class="footer"
      itemtype="http://schema.org/WPFooter"
      itemscope
    >

    </footer>
    <!-- end footer -->
    <!-- <div class="footer-placeholder" id="contact"></div> -->
  </div>
</template>

<script>
import modal from "@/components/ContactModal.vue";

export default {
  name: "app",
  components: {
    modal // eslint-disable-line vue/no-unused-components
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  }
};
</script>

<style lang="scss"> 
@import '../styles/base/_mixins.scss';


.footer-left-kiln {
  display: block;
  position: absolute;
  bottom: 8vw;
  left: 0;
  height: 50vw;
  z-index: -3;
}

.footer-right-kiln {
  display: block;
  position: absolute;
  bottom: 8vw;
  right: 0;
  height: 50vw;
  z-index: -3;
}

.ck-logo {
  width: 26vw;
  height: 6vw;

  @include breakpoint-small-mobile {
    width: 52vw;
    height: 12vw;
  }

  @include breakpoint-mobile{
    width: 52vw;
    height: 12vw;
  }
}

.footer-contact-grid-container{
  @include breakpoint-large-tablet{
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 !important;
    margin-left: 0 !important;
    flex-direction: column;
    align-content: center;
  }

  @include breakpoint-tablet{
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 !important;
    margin-left: 0 !important;
    flex-direction: column;
    align-content: center;
  }

}

.networking-grid-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 30em;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  grid-gap: 0.5em;

  @include breakpoint-large-tablet{
    column-count: 2 !important;
    display: table-row !important;
  }

  @include breakpoint-tablet{
    column-count: 2 !important;
    display: table-row !important;
  }

  @include breakpoint-mobile {
    column-count: 2 !important;
    display: table-row !important;
  }
}

.footer-logo-container {
  flex: 1 0 40%;
  max-width: 100%;
  @include breakpoint-small-mobile {
    flex: 1 1 40%;
    padding: 1.5vw;
  }
  @include breakpoint-mobile {
    flex: 1 1 20%;
    padding: 1.5vw;
  }
  @include breakpoint-tablet {
    flex: 1 1 10%;
    padding: 1em;
  }
  @include breakpoint-large-tablet {
    flex: 1 1 10%;
    padding: 1em;
  }

  @include breakpoint-small-laptop  {
    flex: 1 0 100%;
  }
}

.footer-logo {
  width: 20vw;
  cursor: pointer;
  height: auto;

  @include breakpoint-small-mobile {
    padding: 0.4em;
    width: 24vw;
  }
  @include breakpoint-mobile {
    padding: 0.4em;
    width: 24vw;
  }
  @include breakpoint-tablet {
    padding: 0.4em;
    width: 24vw;
  }
  @include breakpoint-large-tablet {
    padding: 0.4em;
    width: 24vw;
  }
  @include breakpoint-small-laptop  {
    width: 10rem;
  }
}

.footer__bottom {
  z-index: 5;
  padding-bottom: 8em;
  margin-top: 2vw;

  @include breakpoint-small-mobile {
    padding-bottom: 0;
    margin-top: 0;
  }
  @include breakpoint-mobile {
    padding-bottom: 0;
    margin-top: 0;
  }

  @include breakpoint-tablet {
    padding-bottom: 2em;
  }

  @include breakpoint-large-tablet {
    padding-bottom: 0;
    margin-top: 0;
  }
}

</style>