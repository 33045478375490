@@ -0,0 +1,919 @@
<template>
<body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="Privacy"
    itemscope
    itemtype="http://schema.org/WebPage"
    >
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TNTLH4R"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->    
    <main class="main-wrap">
      <div class="content-wrap">

        <!-- CyberKiln privacy Policy -->
        <div class="privacy-container">
          <div class="pt2">
            <div class="privacy-policy">
              <div class="center">
                <h1 class="privacy-policy-title">
                  Cyber Kiln Ltd - Privacy Policy
                </h1>
                <h4 class="privacy-policy-sub-title">
                  1. Introduction
                </h4>      

                <p class="privacy-policy-body-text">                    
                  1.1 We are committed to safeguarding the privacy of Cyber Kiln Ltd website visitors and
                  service users.
                  <br/>
                  1.2 This policy applies where we are acting as a data controller with respect to the personal
                  data of Cyber Kiln Ltd website visitors and service users; in other words, where we
                  determine the purposes and means of the processing of that personal data.
                  <br/>
                  1.3 We use cookies on our website. Insofar as those cookies are strictly necessary for
                  the provision of Cyber Kiln Ltd website and services.
                  <br/>
                  1.4 If, in the event that we do use cookies in the future for marketing purposes, we will ask you to consent to our
                  use of those particular cookies when you first visit our website.
                  <br/>
                  1.5 In this policy, "we", "us" and "our" refer to Cyber Kiln Ltd.For more information about us,
                  see Section 12.
                </p>
                <h4 class="privacy-policy-sub-title">
                  2. How we use your personal data
                </h4> 
                <p class="privacy-policy-body-text">                    
                  2.1 In this Section 2 we have set out:
                  <br/>
                  (a) the general categories of personal data that we may process;
                  <br/>
                  (b) the purposes for which we may process personal data; and
                  <br/>
                  (c) the legal bases of the processing.
                  <br/>
                  2.2 We may process data about your use of our website and services ("usage data"). The
                  usage data may include your IP address, browser type and version, operating system,
                  referral source, length of visit, page views and website navigation paths, as well as
                  information about the timing, frequency and pattern of your service use. The source of
                  the usage data is Google Analytics. This usage data may be processed for the
                  purposes of analysing the use of the website and services. The legal basis for this
                  processing is our legitimate interests, namely monitoring and improving our website and
                  services.
                  <br/>
                  2.3 We may process information contained in any enquiry you submit to us regarding goods
                  and/or services ("enquiry data"). The enquiry data may be processed for the purposes of
                  offering, marketing and selling relevant goods and/or services to you. The legal basis for
                  this processing is consent and our legitimate interests, namely the performance of a
                  contract between you and us and/or the taking steps, at your request, to enter into such a
                  contract.
                  <br/>
                  2.4 We may process any of your personal data identified in this policy where necessary for
                  the establishment, exercise or defence of legal claims, whether in court proceedings or in
                  an administrative or out-of-court procedure. The legal basis for this processing is our
                  legitimate interests, namely the protection and assertion of our legal rights, your legal
                  rights and the legal rights of others.
                  <br/>
                  2.5 We may process any of your personal data identified in this policy where necessary for
                  the purposes of obtaining or maintaining insurance coverage, managing risks, or
                  obtaining professional advice. The legal basis for this processing is our legitimate
                  interests, namely the proper protection of our business against risks.
                  <br/>
                  2.6 In addition to the specific purposes for which we may process your personal data set out
                  in this Section 2, we may also process any of your personal data where such processing
                  is necessary for compliance with a legal obligation to which we are subject, or in order to
                  protect your vital interests or the vital interests of another natural person.
                  Please do not supply any other person's personal data to us, unless we prompt you
                  to do so.
                </p>
                <h4 class="privacy-policy-sub-title">
                  3. Providing your personal data to others
                </h4>
                <p class="privacy-policy-body-text">                     
                  3.1 We may disclose your personal data to our insurers and/or professional advisers insofar
                  as reasonably necessary for the purposes of obtaining or maintaining insurance
                  coverage, managing risks, obtaining professional advice, or the establishment, exercise
                  or defence of legal claims, whether in court proceedings or in an administrative or out-ofcourt
                  procedure.
                  <br/>
                  3.2 Data obtained through our website will be stored on the servers of our hosting services
                  providers <a href="https://www.ionos.co.uk/" target="_blank">https://www.ionos.co.uk/</a> or <a href="https://render.com/" target="_blank">https://render.com/</a>.
                  <br/>
                  3.3 In addition to the specific disclosures of personal data set out in this Section 3, we may
                  disclose your personal data where such disclosure is necessary for compliance with a
                  legal obligation to which we are subject, or in order to protect your vital interests or the
                  vital interests of another natural person. We may also disclose your personal data where
                  such disclosure is necessary for the establishment, exercise or defence of legal claims,
                  whether in court proceedings or in an administrative or out-of-court procedure.
                </p>
                <h4 class="privacy-policy-sub-title">
                  4. International transfers of your personal data
                </h4>
                <p class="privacy-policy-body-text">                    
                  4.1 In this Section 4, we provide information about the circumstances in which your personal
                  data may be transferred to countries outside the European Economic Area (EEA).
                  <br/>
                  4.2 The hosting facilities for our website are situated in the United Kingdom. The European
                  Commission has made an "adequacy decision" with respect to the data protection laws of
                  the United Kingdom. As such all data will be protected by appropriate safeguards,
                  namely the Data Protection Act 2018, a copy of which you can obtain from
                  <a href="https://www.gov.uk/government/collections/data-protection-act-2018" target="_blank">https://www.gov.uk/government/collections/data-protection-act-2018</a>
                  <br/>
                  4.3 You acknowledge that personal data that you submit for publication through our website
                  or services may be available, via the internet, around the world. We cannot prevent the
                  use (or misuse) of such personal data by others.
                </p>
                <h4 class="privacy-policy-sub-title">
                  5. Retaining and deleting personal data
                </h4>
                <p class="privacy-policy-body-text">                   
                  5.1 This Section 5 sets out our data retention policies and procedure, which are designed to
                  help ensure that we comply with our legal obligations in relation to the retention and
                  deletion of personal data.
                  <br/>
                  5.2 Personal data that we process for any purpose or purposes shall not be kept for longer
                  than is necessary for that purpose or those purposes.
                  <br/>
                  5.3 We will retain your personal data as follows:
                  <br/>
                  (a) usage data will be retained for a minimum period of 2 years following the date of
                  collection, and for a maximum period of 6 years following that date;
                  <br/>
                  (b) enquiry data will be retained for a minimum period of 2 following the date of the
                  enquiry, and for a maximum period of 6 years following that date;
                  <br/>
                  (c) transaction data will be retained for a minimum period of 2 years following the date
                  of the transaction, and for a maximum period of 6 years following that date;
                  <br/>
                  5.4 Notwithstanding the other provisions of this Section 5, we may retain your personal data
                  where such retention is necessary for compliance with a legal obligation to which we are
                  subject, or in order to protect your vital interests or the vital interests of another natural
                  person.
                </p>
                <h4 class="privacy-policy-sub-title">
                  6. Your rights
                </h4>
                <p class="privacy-policy-body-text">                  
                  6.1 In this Section 6, we have listed the rights that you have under data protection law.
                  <br/>
                  6.2 Your principal rights under data protection law are:
                  <br/>
                  (a) the right to access - you can ask for copies of your personal data;
                  <br/>
                  (b) the right to rectification - you can ask us to rectify inaccurate personal data and to
                  complete incomplete personal data;
                  <br/>
                  (c) the right to erasure - you can ask us to erase your personal data;
                  <br/>
                  (d) the right to restrict processing - you can ask use to restrict the processing of your
                  personal data;
                  <br/>
                  (e) the right to object to processing - you can object to the processing of your
                  personal data;
                  <br/>
                  (f) the right to data portability - you can ask that we transfer your personal data to
                  another organisation or to you;
                  <br/>
                  (g) the right to complain to a supervisory authority - you can complain about our
                  processing of your personal data; and
                  <br/>
                  (h) the right to withdraw consent - to the extent that the legal basis of our processing
                  of your personal data is consent, you can withdraw that consent.
                  <br/>
                  6.3 These rights are subject to certain limitations and exceptions. You can learn more about
                  the rights of data subjects by visiting <a href="https://ico.org.uk/for-organisations/guide-to-dataprotection/" target="_blank">https://ico.org.uk/for-organisations/guide-to-dataprotection/</a> 
                  guide-to-the-general-data-protection-regulation-gdpr/individual-rights/.
                  <br/>
                  6.4 You may exercise any of your rights in relation to your personal data by written notice to
                  us, using the contact details set out below.
                </p>
                <h4 class="privacy-policy-sub-title">
                  7. About cookies
                </h4>
                <p class="privacy-policy-body-text">                  
                  7.1 A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a
                  web server to a web browser and is stored by the browser. The identifier is then sent
                  back to the server each time the browser requests a page from the server.
                  <br/>
                  7.2 Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will
                  be stored by a web browser and will remain valid until its set expiry date, unless deleted
                  by the user before the expiry date; a session cookie, on the other hand, will expire at the
                  end of the user session, when the web browser is closed.
                  <br/>
                  7.3 Cookies do not typically contain any information that personally identifies a user, but
                  personal data that we store about you may be linked to the information stored in and
                  obtained from cookies.
                </p>
                <h4 class="privacy-policy-sub-title">
                  8. Cookies that we use
                </h4>
                <p class="privacy-policy-body-text">                  
                  8.1 We use cookies for the following purposes:
                  <br/>
                  (a) analysis - we use cookies to help us to analyse the use and performance of our
                  website and services; and
                  <br/>
                  (b) cookie consent - we use cookies to store your preferences in relation to the use of
                  cookies more generally.
                </p>
                <h4 class="privacy-policy-sub-title">
                  9. Cookies used by our service providers
                </h4>
                <p class="privacy-policy-body-text">                  
                  9.1 Our service providers use cookies and those cookies may be stored on your computer
                  when you visit our website.
                  <br/>
                  9.2 We use Google Analytics. Google Analytics gathers information about the use of our
                  website by means of cookies. The information gathered is used to create reports about
                  the use of our website. You can find out more about Google's use of information by
                  visiting <a href="https://www.google.com/policies/privacy/partners/" target="_blank">https://www.google.com/policies/privacy/partners/</a> and you can review Google's
                  privacy policy at <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a> .
                  <br/>
                  9.3 We use Ionos 1&1, CPanel and Render.com to provide web hosting services. This service uses cookies for the
                  creation of reports about website use and security of the hosted material. You can view
                  the privacy policy of this service provider at the respect platform privacy policy page.
                </p>
                <h4 class="privacy-policy-sub-title">
                  10. Managing cookies
                </h4>
                <p class="privacy-policy-body-text">                  
                  10.1 Most browsers allow you to refuse to accept cookies and to delete cookies. The methods
                  for doing so vary from browser to browser, and from version to version. You can however
                  obtain up-to-date information about blocking and deleting cookies via these links:
                  <br/>
                  (a) https://support.google.com/chrome/answer/95647 (Chrome);
                  <br/>
                  (b) https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-websitepreferences
                  (Firefox);
                  <br/>
                  (c) https://help.opera.com/en/latest/security-and-privacy/ (Opera);
                  <br/>
                  (d) https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-deletemanage-
                  cookies (Internet Explorer);
                  <br/>
                  (e) https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-datasfri11471/
                  mac (Safari); and
                  <br/>
                  (f) https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
                  (Edge).
                  <br/>
                  10.2 Blocking all cookies will have a negative impact upon the usability of many websites.
                </p>
                <h4 class="privacy-policy-sub-title">
                  11. Amendments
                </h4>
                <p class="privacy-policy-body-text">                  
                  11.1 We may update this policy from time to time by publishing a new version on our website.
                  <br/>
                  11.2 You should check this page occasionally to ensure you are happy with any changes to
                  this policy.
                </p>
                <h4 class="privacy-policy-sub-title">
                  12. Our details
                </h4>
                <p class="privacy-policy-body-text">                  
                  12.1 This website is owned and operated by Cyber Kiln Ltd, trading as CyberKiln.
                  <br/>
                  12.2 We are registered in England and Wales under registration number 12034623, and our
                  registered office is at 16, Wenlock Close, Newcastle-under-Lyme, Staffordshire ST5 7QF.
                  <br/>
                  12.3 You can contact us:
                  <br/>
                  (a) by post, to the postal address given above;
                  <br/>
                  (b) using our website contact form;or
                  <br/>
                  (c) by email, contact@cyberkiln.co.uk.
                </p>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </main>
  </body>
</template>

<script>
export default {  
  mounted() {
  }
};
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';

.privacy-container {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 6vw;    
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
}

.privacy-policy {
  margin-top: 10rem;
  margin-left: 10vw;
  margin-right: 10vw;
  border-radius: 25px;
  color: black;
  background-image: linear-gradient(263deg, #fc622a, #f8eb70);
  padding: 2em;
}

.privacy-policy-title {
  margin-top: 2vw;
  color: #ffffff;
  text-shadow: 2px 2px 6px black;
  text-align: center;    
}

.privacy-policy-sub-title {
  color: #ffffff;
  text-shadow: 2px 2px 6px black;
  margin-left: auto;
  margin-right: auto;
  width: 70vw;
  margin-top: 2vw;

  @include breakpoint-mobile {
    font-size: 5vw;
    width: auto;
    margin-top: 5vw;
  }
}

.privacy-policy-body-text {
  padding: 2vw;
  margin-left: auto;
  margin-right: auto;
  width: 70vw;
  word-break: break-word;

  @include breakpoint-mobile {
    width: auto;
  }

  a {
    color: #162431 !important;
    cursor: pointer;
    font-weight: bold;
    text-shadow: 2px 2px 3px #ffffff;
  }
}

  
</style>